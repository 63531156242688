import {EventEmitter, Injectable, Output } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';
import {environment} from "../../../environments/environment";
import {GlobleService} from "../../global/globle.service";
@Injectable({
  providedIn: 'root'
})

export class UserRegisterService {

  private baseURL: string = environment.url;
  constructor(
    private http: HttpClient,
    private globalService: GlobleService
  ) { }


  uploadFiles(data)
  {
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+ localStorage.getItem('authToken')
      })
    };
    return this.http.post(this.baseURL + "upload/files",data,httpOption);
  }

  uploadFile(data)
  {
    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+ localStorage.getItem('authToken')
      })
    };
    return this.http.post(this.baseURL + "upload/file",data,httpOption);
  }

  getProvince() {
    return this.http.get(this.baseURL + 'provinces', this.globalService.getHttpOptions());
  }

  getDistrictByProvinceRef(provinceRef) {
    return this.http.get(this.baseURL + 'districts/' + provinceRef,  this.globalService.getHttpOptions());
  }

  getCitiesByDistrictRef(DistrictRef) {
    return this.http.get(this.baseURL + 'cities/' + DistrictRef,  this.globalService.getHttpOptions());
  }

  getSecretaryDivisionsByDistrictRef(DistrictRef) {
    return this.http.get(this.baseURL + 'secretary-divisions/' + DistrictRef,  this.globalService.getHttpOptions());
  }

  getAllRelatedByDistrict(DistrictRef) {
    return this.http.get(this.baseURL + 'get-all-divisions/' + DistrictRef,  this.globalService.getHttpOptions());
  }

  getUserRole(){
    return this.http.get(this.baseURL + 'get-user-roles', this.globalService.getHttpOptions());
  }

  getGramasewaDivision () {
    return this.http.get(this.baseURL + 'get-gramasewa-division', this.globalService.getHttpOptions());
  }


  getDistrictandProvinceByGramasewaDivisionRef (gramasewaDivisionRef) {
    return this.http.get(this.baseURL + 'get-district-province/'+gramasewaDivisionRef, this.globalService.getHttpOptions());
  }

  userRegister(data){
    return this.http.post(this.baseURL + "register",data,this.globalService.getHttpOptions());
  }

  gerGSDivisionsSecRef(secRef: any) {
    return this.http.get(this.baseURL + 'get-gramasewa-divisions/'+ secRef , this.globalService.getHttpOptions());
  }

  getUserDetailsByRef(user_ref) {
    return this.http.get(this.baseURL + 'get-user-details/'+ user_ref , this.globalService.getHttpOptions());
  }
}
