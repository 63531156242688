import {EventEmitter, Injectable, Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GlobleService} from '../global/globle.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private baseURL = environment.url;
  constructor(
    private http: HttpClient,
    private globleService: GlobleService
  ) { }


  getMenu() {
    return this.http.get(this.baseURL + "get-menu", this.globleService.getHttpOptions());
  }

  getMenuFilter(filterdata) {
    return this.http.get(this.baseURL + "get-menu-p?location_id="+filterdata.location_id+"&location_type="+filterdata.location_type+"&question_id="+filterdata.question_id, this.globleService.getHttpOptions());
  }

  getHttpOptions() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
        'Request-Year': localStorage.getItem('year')
      })
    };
  }

  // getDataForm(ques_id, status, gsDivisionRef) {
  //   return this.http.get(this.baseURL + 'ques' + ques_id + '/get-data/' + status + '/' + gsDivisionRef, this.getHttpOptions());
  // }
  // postFromData(ques_id, data) {
  //   return this.http.post(this.baseURL + 'ques' + ques_id + '/save-data', JSON.stringify(data),  this.globleService.getHttpOptions());
  // }


  // getMapData() {
  //   return this.http.get(this.baseURL + 'get-map-data/', this.getHttpOptions());
  // }

  getMapData(locationId,locationType, questionId) {
    return this.http.get(this.baseURL + 'get-map-data?location_id='+locationId+'&location_type='+locationType+'&question_id='+questionId, this.getHttpOptions());
  }

  getDashboardData() {
    return this.http.get(this.baseURL + 'get-dashboard-data', this.getHttpOptions());
  }

    generatePdf(filterdata) {
      return this.http.get(this.baseURL + "generate-pdf-filter?location_id="+filterdata.location_id+"&location_type="+filterdata.location_type+"&question_id="+filterdata.question_id, this.globleService.getHttpOptionsBlob());

    }
}
